import {LitElement, html, css} from 'lit';
import {customElement, property} from 'lit/decorators.js';

@customElement('northell-header')
export class NorthellHeader extends LitElement {
    static override styles = css`
      header {
        display: flex;
        align-items: center;
        flex-grow: 0;
        width: 100%;
        height: 52px;
        justify-content: space-around;
        border-bottom: 1px solid #E8E8E8;
        background-color: var(--white);
      }
      
      div {
        font-size: 16px;
        font-weight: 450;
        line-height: 25.6px;
        color: var(--gray-400);
      }
  `;

    @property() viewTitle: string = '';

    override render() {
        return html`
          <header>
              <div class="container">
                  ${this.viewTitle}
              </div>
          </header>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'northell-header': NorthellHeader;
    }
}
